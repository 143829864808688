﻿﻿﻿/**
 * Plugin 'parameter' : permet d'ajouter des champs dynamiquement de type <input ... />
 * https://docs.ckeditor.com/ckeditor4/latest/api/CKEDITOR_plugins.html
 * https://docs.ckeditor.com/ckeditor4/latest/guide/plugin_sdk_sample.html
 * https://docs.ckeditor.com/ckeditor4/latest/api/CKEDITOR_pluginDefinition.html
 */
CKEDITOR.plugins.add('parameter', {
  icons: 'add-field',
  init: function (editor) {
    // console.log('parameter loaded !! on ', editor);

    editor.addCommand('insertparameter', {
      exec: function (editor) {
        // console.log('parameter executed !!');

        if(document.querySelector('.info-field')) {
          var elt = document.querySelector('.info-field');

          // console.log('elt ', elt);
          var maxIdx = 0;

          var txt = '';
          if ( "cells" in elt )
            txt = elt.cells[0].textContent;
          else
            txt = elt.textContent;

          // console.log('txt ', txt);

          var id = elt.id;
          var data = editor.getData();

          if(data) {
            var doc = editor.document;
            var inputs = doc.$.getElementsByClassName('parameter-inserted');
            for(var i =0; i < inputs.length; i++) {
              var idx = Number(inputs[i].dataset.index);
              if(idx > maxIdx) {
                maxIdx = idx;
              }
            }
          }

          var html = '<input type="text" class="parameter-inserted" data-field="' + id + '" data-index="' + (Number(maxIdx) + 1) + '" value="' + txt.trim() + '"/>';

          // console.log('html ', html);

          editor.insertHtml(html);
        }
      }
    });

    // https://docs.ckeditor.com/ckeditor4/latest/api/CKEDITOR_ui.html
    editor.ui.addButton('InsertParameter', {
      label: 'Ajouter champ',
      command: 'insertparameter',
      toolbar: 'document',
      icon: '/assets/scripts/ckeditor/parameter/icons/add-field.png'
    });
  }
});
